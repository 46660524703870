import { createSelector } from '@reduxjs/toolkit';
import { SPI_PAIR_STATUS, SPI_TRANSACTION_TYPES } from '../../../definitions/constants/commonConfigs';
import {
  PATH_CASH_OUT,
  PATH_PURCHASE,
  PATH_REFUND,
  PATH_ACCOUNT_VERIFY,
  PATH_PRE_AUTH,
  TEXT_CASHOUT,
  TEXT_PURCHASE,
  TEXT_REFUND,
  TEXT_ACCOUNT_VERIFY,
  TEXT_PRE_AUTH,
} from '../../../definitions/constants/routerConfigs';
import { TxFlowState } from '../../../definitions/constants/terminalConfigs';
import { RootState } from '../../store';

export const terminalMap = (state: RootState) => {
  const { _persist, ...terminals } = state.terminals;
  return terminals;
};

export const terminalList = createSelector(terminalMap, (tMap) => Object.values(tMap));

export const terminalInstance = (instanceId: string) =>
  createSelector(terminalMap, (terminals) => {
    // The terminalmap type assumes that any string key is a valid pairing
    // this isn't true when doing a lookup, so the easiest way to handle this is to
    // check for the key in the object
    if (instanceId in terminals) {
      return terminals[instanceId];
    }
    return undefined;
  });

export const pairedConnectedTerminalList = createSelector(terminalList, (terminals) =>
  terminals.filter((terminal) => !!terminal && terminal?.status === SPI_PAIR_STATUS.PairedConnected)
);

export const selectHasPairedTerminals = createSelector(
  pairedConnectedTerminalList,
  (terminals) => terminals.length > 0
);

export const terminalPairingFlow = (instanceId: string) =>
  createSelector(terminalInstance(instanceId), (terminal) => terminal?.pairingFlow);

export const isTerminalUnpaired = (instanceId: string) =>
  createSelector(
    terminalInstance(instanceId),
    (terminal) =>
      terminal?.status === SPI_PAIR_STATUS.PairedConnecting || terminal?.status === SPI_PAIR_STATUS.PairedConnected
  );

export const terminalPosRefId = (instanceId: string) =>
  createSelector(terminalInstance(instanceId), (terminal) => terminal?.txFlow?.request?.data?.posRefId);

export const terminalTransactionTypeObject = (instanceId: string) =>
  createSelector(terminalInstance(instanceId), (terminal) => {
    switch (terminal?.txFlow?.type) {
      case SPI_TRANSACTION_TYPES.CashoutOnly:
        return {
          typePath: PATH_CASH_OUT,
          typeTitle: TEXT_CASHOUT,
        };
      case SPI_TRANSACTION_TYPES.Refund:
        return {
          typePath: PATH_REFUND,
          typeTitle: TEXT_REFUND,
        };
      case SPI_TRANSACTION_TYPES.Preauth:
        return {
          typePath: PATH_PRE_AUTH,
          typeTitle: TEXT_PRE_AUTH,
        };
      case SPI_TRANSACTION_TYPES.AccountVerify:
        return {
          typePath: PATH_ACCOUNT_VERIFY,
          typeTitle: TEXT_ACCOUNT_VERIFY,
        };
      default:
        return {
          typePath: PATH_PURCHASE,
          typeTitle: TEXT_PURCHASE,
        };
    }
  });

export const terminalTxFlowReceiptContent = (instanceId: string) =>
  createSelector(terminalInstance(instanceId), (terminal) => terminal?.receipt?.merchantReceipt);

export const terminalTxFlowReceipt = (instanceId: string) =>
  createSelector(terminalInstance(instanceId), (terminal) => terminal?.receipt);

export const terminalTxAmount = (instanceId: string) =>
  createSelector(terminalInstance(instanceId), (terminal) => terminal?.txFlow?.amountCents);

export const terminalTxFlowSuccessTracker = (instanceId: string) =>
  createSelector(terminalInstance(instanceId), (terminal) => terminal?.txFlow?.success);

export const isTerminalTxFlowSuccess = (instanceId: string) =>
  createSelector(terminalInstance(instanceId), (terminal) => terminal?.txFlow?.success === TxFlowState.Success);

export const terminalTxFlowFinishedTracker = (instanceId: string) =>
  createSelector(terminalInstance(instanceId), (terminal) => terminal?.txFlow?.finished);

export const terminalTxFlowAwaitingSignatureTracker = (instanceId: string) =>
  createSelector(terminalInstance(instanceId), (terminal) => terminal?.txFlow?.awaitingSignatureCheck);

export const terminalTxTotalAmount = (instanceId: string) =>
  createSelector(
    terminalInstance(instanceId),
    (terminal) =>
      (terminal?.txFlow?.response?.data?.purchaseAmount as number) +
      (terminal?.txFlow?.response?.data?.surchargeAmount as number) +
      ((terminal?.txFlow?.response?.data?.bankCashAmount as number) || 0) +
      ((terminal?.txFlow?.response?.data?.tipAmount as number) || 0)
  );

export const terminalTxMessage = (instanceId: string) =>
  createSelector(terminalInstance(instanceId), (terminal) => terminal?.txMessage);
