import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { useTransactionDetailPageStyle } from '../TransactionPage/TransactionDetailsPage/TransactionDetailsPage.style';
import { SpiCloudPairing } from '../../redux/reducers/PairingSlice/interfaces';

interface Props {
  pairing?: SpiCloudPairing;
}

export function CloudOrderPairingInfo({ pairing }: Props) {
  const classes = useTransactionDetailPageStyle();

  return (
    <div className={classes.orderInfo}>
      {pairing?.pairingNickname && (
        <>
          <Typography className={classes.body}>Pairing nickname:</Typography>
          <Box bgcolor="rgba(234, 236, 240, 1)" paddingX={1} width="max-content" borderRadius={4} overflow="auto">
            <Typography variant="body2" component="code">
              {pairing.pairingNickname}
            </Typography>
          </Box>
        </>
      )}
      <Typography className={classes.body}>Pairing ID:</Typography>
      <Box bgcolor="rgba(234, 236, 240, 1)" paddingX={1} width="max-content" borderRadius={4} overflow="auto">
        <Typography variant="body2" component="code">
          {pairing?.pairingId}
        </Typography>
      </Box>
      {pairing?.terminalNickname && (
        <>
          <Typography className={classes.body}>Terminal nickname:</Typography>
          <Box bgcolor="rgba(234, 236, 240, 1)" paddingX={1} width="max-content" borderRadius={4} overflow="auto">
            <Typography variant="body2" component="code">
              {pairing.terminalNickname}
            </Typography>
          </Box>
        </>
      )}
      <Typography className={classes.body}>TID:</Typography>
      <Box bgcolor="rgba(234, 236, 240, 1)" paddingX={1} width="max-content" borderRadius={4} overflow="auto">
        <Typography variant="body2" component="code">
          {pairing?.tid}
        </Typography>
      </Box>
    </div>
  );
}
