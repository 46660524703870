import { Typography } from '@material-ui/core';
import React from 'react';
import OrderSubTotal from '../OrderSubTotal';
import { CloudTransactionResultAmounts } from '../../transaction-handling/cloud-transaction';
import { useTransactionDetailPageStyle } from '../TransactionPage/TransactionDetailsPage/TransactionDetailsPage.style';

interface Props {
  resultAmounts?: CloudTransactionResultAmounts;
}

export function CloudOrderSummary({ resultAmounts }: Props) {
  const classes = useTransactionDetailPageStyle();

  if (!resultAmounts) {
    return null;
  }

  const { cashout_amount, moto_amount, purchase_amount, refund_amount, surcharge_amount, tip_amount } = resultAmounts;

  return (
    <>
      <Typography className={classes.heading}>Order Summary</Typography>
      {!!purchase_amount && <OrderSubTotal label="Purchase" amount={purchase_amount} />}
      {!!refund_amount && <OrderSubTotal label="Refund" amount={refund_amount} />}
      {!!cashout_amount && <OrderSubTotal label="Cashout" amount={cashout_amount} />}
      {!!moto_amount && <OrderSubTotal label="Moto" amount={moto_amount} />}
      {!!surcharge_amount && <OrderSubTotal label="Surcharge" amount={surcharge_amount} />}
      {!!tip_amount && <OrderSubTotal label="Tip" amount={tip_amount} />}
    </>
  );
}
