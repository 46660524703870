import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';

const developmentOnlyPairings = (state: RootState) => {
  const { _persist, ...pairings } = state.pairings;

  if (state.currentEnv?.env === 'DEV' || process.env.NODE_ENV === 'development') {
    return pairings;
  }

  return {};
};

export const pairingsMap = (state: RootState) => {
  const { _persist, ...pairings } = state.pairings;
  return pairings;
};

export const selectCloudPairings = (options?: { force?: boolean }) => {
  // TODO: FE-20 - Remove this check to "release" to production
  //    - Always return cloud pairings
  //    - Remove force override and options object
  //    - Update usages from selectCloudPairings() to selectCloudPairings
  if (options?.force) {
    return createSelector(pairingsMap, (pairings) => Object.values(pairings));
  }

  return createSelector(developmentOnlyPairings, (pairings) => Object.values(pairings));
};

export const selectHasCloudPairings = createSelector(selectCloudPairings(), (pairings) => pairings.length > 0);

export const selectCloudPairing = (id: string) =>
  createSelector(pairingsMap, (pairings) => {
    // The pairingMap type assumes that any string key is a valid pairing
    // this isn't true when doing a lookup, so the easiest way to handle this is to
    // check for the key in the object
    if (id in pairings) {
      return pairings[id];
    }
    return undefined;
  });
