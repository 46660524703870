import React, { useMemo } from 'react';
import { Box, Container, Divider, Grid, Typography } from '@material-ui/core';
import { OrderButtons } from '../PaymentSummary/OrderButtons';
import CustomContentPanel from '../CustomContentPanel';
import { useTransactionDetailPageStyle } from '../TransactionPage/TransactionDetailsPage/TransactionDetailsPage.style';
import { CloudTransactionResponse } from '../../transaction-handling/cloud-transaction';
import { SpiCloudPairing } from '../../redux/reducers/PairingSlice/interfaces';
import { PATH_CASH_OUT, PATH_PURCHASE, PATH_REFUND } from '../../definitions/constants/routerConfigs';
import { CloudOrderSummary } from '../CloudOrderSummary/CloudOrderSummary';
import { CloudOrderPairingInfo } from '../CloudOrderPairingInfo/CloudOrderPairingInfo';

interface Props {
  pairing?: SpiCloudPairing;
  transactionResponse?: CloudTransactionResponse;
}

export function CloudPaymentSummary({ pairing, transactionResponse }: Props) {
  const classes = useTransactionDetailPageStyle();

  const typePath = useMemo(() => {
    if (!transactionResponse?.data?.result_amounts) {
      return PATH_PURCHASE;
    }

    const { cashout_amount, purchase_amount, refund_amount } = transactionResponse.data.result_amounts;

    if (refund_amount) {
      return PATH_REFUND;
    }

    if (cashout_amount && !purchase_amount) {
      return PATH_CASH_OUT;
    }

    return PATH_PURCHASE;
  }, [transactionResponse?.data?.result_amounts]);

  return (
    <Container className={classes.container} maxWidth="md">
      <Grid container spacing={1} className={classes.gridContainer}>
        <Grid item xs={6} className={classes.gridItem}>
          <Box className={classes.root}>
            <Box flexGrow="2" className={classes.roots}>
              <Typography variant="h6" component="h1">
                {transactionResponse?.data?.message}
              </Typography>
              <Divider className={classes.divider} variant="middle" />
              <CloudOrderPairingInfo pairing={pairing} />
              <Divider className={classes.divider} variant="middle" />
              <CloudOrderSummary resultAmounts={transactionResponse?.data?.result_amounts} />
              <OrderButtons typePath={typePath} />
            </Box>
          </Box>
        </Grid>
        <Grid className={classes.gridItem}>
          <CustomContentPanel
            title="Receipt"
            css={classes.receiptBoxWrapper}
            isCopiable
            content={transactionResponse?.data?.merchant_receipt}
          >
            <pre>{transactionResponse?.data?.merchant_receipt || transactionResponse?.data?.message}</pre>
          </CustomContentPanel>
        </Grid>
      </Grid>
    </Container>
  );
}
