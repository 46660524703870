import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Layout from '../Layout';
import { CloudPaymentSummary } from '../CloudPaymentSummary/CloudPaymentSummary';
import { selectCloudPairing } from '../../redux/reducers/PairingSlice/pairingSelectors';
import { useFetchCloudTransaction } from '../../transaction-handling/use-fetch-cloud-transaction';
import { clearAllProducts } from '../../redux/reducers/ProductSlice/productSlice';

interface Params {
  pairingId: string;
  transactionId: string;
}

export function CloudOrderFinished() {
  const { pairingId, transactionId } = useParams<Params>();
  const pairing = useSelector(selectCloudPairing(pairingId));

  if (!pairing) {
    return <div>Could not load pairing.</div>;
  }

  const { isError, isLoading, transactionResponse } = useFetchCloudTransaction({ pairing, transactionId });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearAllProducts());
  }, []);

  if (isError) {
    return <div>Could not load transaction.</div>;
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Layout>
      <CloudPaymentSummary pairing={pairing} transactionResponse={transactionResponse} />
    </Layout>
  );
}
